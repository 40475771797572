<template>
    <div class="authorize">
       
        <div class="content">
            <p>{{$t("authorize_p1")}}：</p>
            <p>{{$t("authorize_p2")}}：</p>
            <p>{{$t("authorize_p3")}}</p>
            <p>{{$t("authorize_p4")}}：</p>
            <p>{{$t("authorize_p5")}}</p>
            <p>{{$t("authorize_p6")}}</p>
            <p>{{$t("authorize_p7")}}：</p>
            <p>{{$t("authorize_p8")}}</p>
            <p>{{$t("authorize_p9")}}</p>
            <p>{{$t("authorize_p10")}}</p>
            <p>{{$t("authorize_p11")}}</p>
            <p>{{$t("authorize_p12")}}</p>


            <p>{{$t("authorize_p13")}}</p>
            <p>{{$t("authorize_p14")}}</p>

            <p>{{$t("authorize_p15")}}：</p>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    methods:{
        back(){
            this.$router.go(-1)
        }
    }
}
</script>

<style scoped lang="less">
.authorize{
    width: 100%;
    background-color: @recommendMusicianListBGColor;
    position: relative;
	color: @recommendMusicianListTextColor;
	margin-top: 20px;
	border-radius: 6px;
	
	.content{
		padding: 10px;
		p{
			line-height: 35px;
			font-size: 16px;
		}
	}
}
.head{
    width: 100%;
    height: 55px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
    text-align: center;
    line-height: 55px;
}
.back{
    position: absolute;
    top: 0;
    left: 10px;
    font-size: 20px;
    cursor: pointer;
}
</style>